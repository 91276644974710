import React, { useRef, useState } from 'react';
import Slider from 'react-slick';
import classNames from 'classnames';
import css from './CarouselBanner.scss';
import PropTypes from 'prop-types';

function replaceTokensInUrls(url, width) {
    return url.replace(/\$ext\$/g, 'webp').replace(/\$width\$/g, width);
}

function getImageItems(originalLayout) {
    // Destructure the originalLayout object
    const { sections } = originalLayout;

    const filteredSections = sections[0];

    return filteredSections && filteredSections.widgets[0].data.items && filteredSections.widgets[0].data.items.length > 0 ? filteredSections.widgets[0].data.items : [];
}

function getResolutionConfig(layout) {
    // Destructure the originalLayout object
    const { sections } = layout;

    const filteredSections = sections[0];

    return filteredSections && filteredSections.widgets[0].data.fileRestrictions ? filteredSections.widgets[0].data.fileRestrictions : { width: 1400, height: 250 };
}

function renderArrowAsset(layout, previous, next) {
    const { sections } = layout;

    const bannerSection = sections[0];

    if (bannerSection.widgets[0].data.items.length > 1) {
        const GetAssets = bannerSection.widgets[0].data?.assets && bannerSection.widgets[0].data?.assets[0];

        return GetAssets?.items.map(arrow => <span data-aut-id={ arrow.type === 'leftArrow' ? 'slider-arrow-previous' : 'slider-arrow-next' } dangerouslySetInnerHTML={ { __html: arrow.element } } style={ { ...GetAssets.style, ...arrow.style } } onClick={ arrow.type === 'leftArrow' ? previous : next } />);
    }

    return <></>;
}

export const CarouselBanner = ({
    layout
}) => {
    const slider = useRef(null);
    const [currentIdx, updateCurrentIdx] = useState(0);

    function handleDotClick(idx) {
        slider.current.slickGoTo(idx);
        updateCurrentIdx(idx);
    }

    function onAfterChange(newIdx) {
        updateCurrentIdx(newIdx);
    }

    const images = getImageItems(layout);

    function next(ev) {
        ev.stopPropagation();
        slider.current.slickNext();
    }

    function previous(ev) {
        ev.stopPropagation();
        slider.current.slickPrev();
    }

    const settings = {
        arrows: false,
        pauseOnHover: true,
        dots: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <></>,
        prevArrow: <></>,
        autoplay: images.length > 1, // Set to true to enable autoplay
        autoplaySpeed: 5000 // Set the autoplay speed in milliseconds (e.g., 3000ms for 3 seconds)
    };

    return (
        <div style={ { position: 'relative' } }>
            {renderArrowAsset(layout, previous, next)}

            <Slider { ...settings } afterChange={ onAfterChange } initialSlide={ 0 } ref={ slider } lazyLoad="progressive">
                {images.map((image, index) => {
                    return (
                        <div key={ index } style={ { cursor: 'pointer' } }>
                            <img
                                src={ replaceTokensInUrls(image.image.url, getResolutionConfig(layout).width) }
                                alt={ `Slide ${index + 1}` }
                                style={ { ...image.image.style, height: getResolutionConfig(layout).height, objectFit: 'cover' } }
                                onClick={ () => {
                                    if (typeof window !== 'undefined' && image.target === '_blank') {
                                        // eslint-disable-next-line no-undef
                                        window.open(image.action, '_blank')?.focus();
                                    }
                                } }
                            />
                        </div>
                    );
                })}
            </Slider>
            <div
                className={ classNames(
                    css.dotContainer,
                    {
                        [css.dFlex]: images.length > 1,
                        [css.dNone]: images.length <= 1
                    }) }
            >
                {images.map((_, idx) => (
                    <div
                        data-aut-id={ `carousel-slide-${idx}` }
                        key={ idx }
                        className={ classNames(css.dotItem) }
                        style={ {
                            backgroundColor: idx === currentIdx ? 'rgba(10, 10, 10, 0.8)' : 'gray'
                        } }
                        onClick={ () => handleDotClick(idx) }
                    />))}
            </div>
        </div>
    );
};

CarouselBanner.propTypes = {
    layout: PropTypes.object
};

export default CarouselBanner;
