import '@olxid/olx-autos-landing-page/dist/Components/Bundle/SliderArrow/SliderArrow.scss';
import '@olxid/olx-autos-landing-page/dist/Widgets/NewsCarousel/NewsCarousel.scss';
import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NewsCarousel as NewsCarouselWidget } from 'olx-autos-landing-page';

const SECTION_NAME = 'news-section';

const NewsCarousel = ({ layout }) => {
    if (!layout && !layout?.sections) {
        return null;
    }
    const newsSection = layout.sections.find(section => section.name.includes(SECTION_NAME));
    const widget = newsSection?.widgets?.find(widget => widget.templateName === 'NewsCarousel');

    if (!widget) {
        return null;
    }

    return (
        <NewsCarouselWidget { ...widget } />
    );
};

NewsCarousel.propTypes = {
    layout: PropTypes.object
};

NewsCarousel.defaultProps = {
    layout: {}
};

export const mapStateToProps = state => {
    const layout = state?.cxeLayout?.layouts[Object.keys(state.cxeLayout.layouts)[0]] || null;

    return {
        layout
    };
};

export default compose(
    connect(mapStateToProps)
)(React.memo(NewsCarousel));
